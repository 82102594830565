import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/taggedTemplateLiteralLoose";
var _templateObject;
import styled from 'styled-components';
export * from './Document';
export * from './Divider';
export * from './Result';
export * from './Button';
export * from './CodeLineCopy';
export * from './CheckboxOption';
export * from './Spacing';
export * from './Select';
export * from './Input';
export * from './StyledLayout';
export * from './Textarea';
export * from './utils';
export * from './ErrorLayout';
export * from './CodeEditor';
export * from './LayoutEditorConverter';
export var Wrapper = styled.main(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n  padding: 25px;\n  width: 100%;\n  min-height: 100%;\n  display: flex;\n  gap: 25px;\n"])));